import React, { useState, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';
import elasticlunr from 'elasticlunr';
import { MagnifyingGlassIcon, ArrowDownOnSquareStackIcon, XCircleIcon, UserCircleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import Layout from '../components/layout';

const Modal = ({ profile, onClose }) => {
  return (
    <div className="fixed bg-gray-400 bg-opacity-75 inset-0 w-screen overflow-y-auto z-50">
      <div className="flex min-h-full items-center justify-center p-5 text-center sm:items-center sm:p-0">
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-4xl sm:w-full">
          <div className="flex absolute right-3 top-3">
            <button
              type="button"
              className=" w-full inline-flex justify-center shadow-sm rounded-full bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
            >
              <XCircleIcon className="h-10 w-10 text-blue-500 hover:text-blue-600" aria-hidden="true" />
            </button>
          </div>
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-5">
            <div className="sm:flex sm:items-start">
              <div className="mt-10 mb-5 lg:my-5 text-center lg:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4" id="modal-title">
                  {profile.title}
                </h3>
                <div className="mt-2">
                  <img src={profile.logo} alt={`${profile.title} Logo`} className="h-20 mx-auto lg:m-0" />
                  <p className="text-sm text-gray-500 my-5">
                    {profile.tagline}
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    <a href={profile.website} target="_blank" rel="noopener noreferrer" className="text-grey-800 hover:underline">{profile.website}</a>
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    {profile.address}
                  </p>
                  <p className="text-sm text-gray-500 mt-2 px-3 lg:px-0">
                    {profile.rawMarkdownBody}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 gap-x-3 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <a href={profile.website} target="_blank" rel="noopener noreferrer" className="">
              <button
                type="button"
                className="my-2 w-full text-center flex lg:inline-flex gap-8 items-center justify-center lg:w-auto gap-x-5 rounded-md bg-green-600 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-green-700"
              >
                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-white" aria-hidden="true" />
                Visit Website
              </button>
            </a>
            <a
              href={`/agency/profile/${profile.slug}`}
              className=""
              target="_blank"
            >
              <button
                type="button"
                className="my-2 w-full text-center flex lg:inline-flex gap-8 items-center justify-center lg:w-auto gap-x-5 rounded-md bg-blue-500 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600"
              >
                <UserCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                View Profile
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};


const SearchPage = () => {
  const [index, setIndex] = useState(null);
  const [results, setResults] = useState([]);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndexLoading, setIsIndexLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const itemsPerPage = 20;
  const searchInputRef = useRef();

  const loadIndex = async () => {
    setIsIndexLoading(true);
    const searchIndexUrl = process.env.GATSBY_SEARCH_INDEX_URL || '/search_index.json';

    try {
      const response = await fetch(searchIndexUrl);
      const indexJson = await response.json();
      const loadedIndex = elasticlunr.Index.load(indexJson);
      setIndex(loadedIndex);
      return loadedIndex; // Return the loaded index
    } catch (error) {
      console.error('Failed to load search index:', error);
    } finally {
      setIsIndexLoading(false);
    }
  };

  const executeSearch = (searchQuery, indexToUse) => {
    if (!indexToUse) return;
  
    setIsLoading(true);
    const searchResults = indexToUse.search(searchQuery, {}).map(({ ref }) => {
      const doc = indexToUse.documentStore.getDoc(ref);
      return {
        title: doc.title,
        rawMarkdownBody: doc.rawMarkdownBody,
        logo: doc.logo,
        website: doc.website,
        tagline: doc.tagline,
        address: doc.address,
        slug: doc.slug
      };
    });
  
    setResults(searchResults);
    setDisplayedResults(searchResults.slice(0, itemsPerPage));
    setCurrentIndex(itemsPerPage);
    setHasMore(searchResults.length > itemsPerPage);
  
    setIsLoading(false);
  };

  const handleSearch = async () => {
    const searchQuery = searchInputRef.current.value;
    if (!index) {
      const loadedIndex = await loadIndex();
      executeSearch(searchQuery, loadedIndex); 
    } else {
      executeSearch(searchQuery, index); 
    }
  };

  const loadMore = () => {
    const nextIndex = currentIndex + itemsPerPage;
    if (nextIndex <= results.length) {
      setDisplayedResults(prevDisplayed => [
        ...prevDisplayed,
        ...results.slice(currentIndex, nextIndex)
      ]);
      setCurrentIndex(nextIndex);
      setHasMore(nextIndex < results.length);
    } else {
      setHasMore(false);
    }
  };

  const truncateText = (text, length = 250) => {
    return text && text.length > length ? text.substring(0, length) + '...' : text;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleProfileSelect = (profile) => {
    setSelectedProfile(profile);
  };

  const handleCloseModal = () => {
    setSelectedProfile(null);
  };

  return (
    <Layout>
      <div className="container w-full px-5 py-5 lg:px-5 bg-white mx-auto lg:my-10 lg:rounded-lg overflow-hidden lg:shadow-lg">
        <h1 className="text-black font-semibold text-lg">Search Agencies</h1>
        <div className="py-5">
          <div className="relative mt-2 rounded-md">
            <div className="hidden lg:flex pointer-events-none absolute inset-y-0 left-0  items-center lg:pl-2">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              ref={searchInputRef}
              type="search"
              placeholder="Search..."
              className="focus:outline-none focus:ring-2 focus:ring-blue-500 ring-2 ring-gray-200 h-12 focus:border-transparent block w-2/3 rounded-tl-md rounded-bl-md border py-3 px-3 lg:pl-8 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
              onKeyPress={handleKeyPress}
            />
            <button onClick={handleSearch} className="absolute right-0 top-0 px-10 h-12 text-white bg-blue-500 w-1/3 ring-2 ring-blue-500  py-3 text-sm font-semibold shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 rounded-tr-md rounded-br-md">
              Search
            </button>
          </div>

          {isIndexLoading ? (
            <div className="inline-block w-full text-center mt-5 mx-auto">
              <button
                type="button"
                className="inline-flex max-w-5xl gap-x-2 rounded-md bg-blue-500 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> Loading search index...
              </button>
            </div>
          ) : isLoading ? (
            <div className="inline-block w-full text-center mt-5 mx-auto">
              <button
                type="button"
                className="inline-flex max-w-5xl gap-x-2 rounded-md bg-blue-500 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> Loading results...
              </button>
            </div>
          ) : (
            <>
              <ul role="list" className="profileList my-4">
                {displayedResults.map(profile => (
                  <li key={profile.slug} className="flex justify-between hover:bg-gray-100 cursor-pointer" onClick={() => handleProfileSelect(profile)}>
                    <div className="w-full py-5 border-b border-gray-300 px-4 text-black flex justify-between">
                      <div className="flex min-w-0 gap-x-4">
                        <img className="h-12 w-auto flex-none" src={profile.logo} alt={`${profile.title} Profile`} />
                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold">{profile.title}</p>
                          <p className="mt-1 truncate text-xs">{profile.tagline}</p>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <div className="text-xs w-96">
                          <p className="leading-4 contents">
                            {truncateText(profile.rawMarkdownBody)}
                          </p>
                          <span className="ml-1">read more</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              {hasMore && (
                <div className="inline-block w-full text-center mt-5 mx-auto">
                  <button
                    onClick={loadMore}
                    type="button"
                    className="inline-flex max-w-5xl gap-x-1 rounded-md bg-blue-500 px-5 py-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    <ArrowDownOnSquareStackIcon className="animate-bounce -ml-1 mr-3 h-5 w-5 text-white" aria-hidden="true" />
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {selectedProfile && (
        <Modal profile={selectedProfile} onClose={handleCloseModal} />
      )}
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/agency/profile/" } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          slug
          tagline
          logo
          website
          address
        }
        rawMarkdownBody
      }
    }
  }
`;

export default SearchPage;